import axios from "axios";
import { useRouter } from "next/router";
import { parseCookies } from "nookies";
import { createContext, useContext, useEffect, useState } from "react";
import API_URL from "../../constants/api";

const AppContext = createContext();

export default function Provider({ children }) {
  const { ["email"]: token } = parseCookies();
  const [userTeste, setUserTeste] = useState();
  const router = useRouter();
  useEffect(() => {
    const email = { email: token };
    if (email.email)
      axios
        .post(`${API_URL}/auth/user_info`, email)
        .then((response) => {
          setUserTeste(response.data.user);
        })
        .catch((err) => {
          alert(err);
        });
  }, [router]);

  return (
    <AppContext.Provider value={userTeste}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
