import "../styles/global.css";
import { ThemeProvider } from "next-themes";
import Provider from "./context";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <ThemeProvider enableSystem={false} attribute="class" defaultTheme="dark">
        <Provider>
          <Component {...pageProps} />
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
